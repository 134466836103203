<template>
    <b-overlay :show="loading">
        <kas-modal @submitKas="approve"></kas-modal>
        <div v-if="canConfirm" class="d-flex justify-content-end mb-2">
            <b-button v-if="penjualan.selesai == 0" @click.prevent="openKasModal" class="mr-1" variant="success" size="sm">Pilih Sumber Pendapatan</b-button>
            <b-button v-if="penjualan.selesai == 1" @click.prevent="confirmCancel" class="mr-1" variant="outline-danger" size="sm">Tolak</b-button>
        </div>
        <b-card-actions :title="penjualan ? penjualan.no : '-'" class="mb-2" action-collapse>
            <b-row class="justify-content-between align-items-center">
                <b-col sm="12" md="8" lg="10">
                    <table>
                        <tr>
                            <td>Nama</td>
                            <td>:</td>
                            <th>{{penjualan.nama}}</th>
                        </tr>
                        <tr>
                            <td>Tanggal</td>
                            <td>:</td>
                            <th>{{penjualan.tanggal}}</th>
                        </tr>
                        <tr>
                            <td>Keterangan</td>
                            <td>:</td>
                            <th>{{penjualan.keterangan}}</th>
                        </tr>
                        <tr>
                            <td>Status</td>
                            <td>:</td>
                            <th>
                                <b-badge v-if="penjualan.selesai == 0" variant="light-danger">
                                    Belum Selesai
                                </b-badge>
                                <b-badge v-else variant="light-success">
                                    Selesai
                                </b-badge>
                            </th>
                        </tr>
                    </table>
                </b-col>
                <b-col sm="12" md="4" lg="2">
                    <small class="d-block text-center">Total</small>
                    <div class="d-flex justify-content-center">
                        <h4 class="text-danger">
                            <strong>Rp {{ formatRupiah(totalRincian) }}</strong> </h4>
                    </div>
                </b-col>
            </b-row>
        </b-card-actions>

        <b-row>
            <b-col cols="12" class="mb-2">
                <b-button variant="primary" class="mb-1" v-if="allowCreate()" @click.prevent="openModalAsset">
                    Pilih Aset
                </b-button>
                <b-card :title="`Rincian Aset ${selectedPenyimpanans.length > 0 ? '('+ selectedPenyimpanans.length +')' : '' }`">
                    <b-table responsive :fields="fields" :items="selectedPenyimpanans">
                        <template #cell(no)="{index}">
                            {{++index}}
                        </template>
                        <template #cell(nama)="{item}">
                            <span v-if="item.asset">{{item.asset.nama}}</span>
                            <i class="text-danger" v-else>Aset tidak ditemukan</i>
                        </template>
                        <template #cell(harga_jual)="{item}">
                            Rp {{ formatRupiah(item.harga_jual) }}
                        </template>
                        <template #cell(satuan)="{item}">
                            <span v-if="item.asset && item.asset.satuan">
                                {{item.asset.satuan.satuan}}
                            </span>
                            <i class="text-danger" v-else>{{item.id_satuan}}</i>
                        </template>
                        <template #cell(actions)="{item, index}">
                            <feather-icon icon="TrashIcon" class="text-danger cursor-pointer" @click="remove(item, index)" size="24" ></feather-icon>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
            <b-col cols="12" v-if="allowCreate()">
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" @click.prevent="submit" :disabled="selectedPenyimpanans.length < 1 || penjualan.selesai == 1">Simpan</b-button>
                </div>
            </b-col>
        </b-row>

        <modal-penyimpanan @chooseAsset="onChooseAsset"></modal-penyimpanan>
    </b-overlay>
</template>
<script>
import {BBadge, BOverlay, BRow, BCol, BCard, BTable, BButton} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import ModalPenyimpanan from './components/ModalPenyimpanan.vue'
import KasModal from './components/ModalKas.vue'
export default {
    components: {
        KasModal, ModalPenyimpanan, BBadge, BOverlay, BRow, BCol, BCard, BTable, BButton, BCardActions
    },
    computed: {
        totalRincian() {
            if(this.selectedPenyimpanans.length < 1) {
                return 0
            }

            return this.selectedPenyimpanans.reduce((total, item) => total += (parseInt(item.jumlah) * parseInt(item.harga_jual)), 0)
        },
        penjualan() {
            const penjualan = this.$store.getters['asset/searchPenjualan'](this.$route.params.id)
            if(!penjualan) {
                this.$router.push(`/asset-penjualan`)
            }
            return penjualan
        }
    },
    data: () => ({
        loading: false,
        selectedPenyimpanans: [],
        fields: [
            {key: 'no', label: 'No'},
            {key: 'nama', label: 'Nama Aset'},
            {key: 'harga_jual', label: 'Harga Jual'},
            {key: 'jumlah', label: 'Qty'},
            {key: 'satuan', label: 'Satuan'},
            {key: 'actions', label: 'Aksi'}
        ],
        canConfirm: false,
        confirmOption: {
            title: 'Anda yakin?',
            text: `Anda akan membatalkan penjualan ini`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Batal',
            customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger ml-1',
            },
            buttonsStyling: false
        }
    }),
    methods: {
        confirmCancel() {
            this.$swal(this.confirmOption)
            .then(res => {
                if(res.value) {
                    this.cancel()
                }
            })
        },
        async cancel() {
            const params = {
                penjualan_id: this.$route.params.id,
                id_akun: 0,
                cancel: 1
            }

            try {
                this.loading = true
                await this.$store.dispatch('asset/confirmPenjualan', [params])
                this.loading = false
                this.displaySuccess({
                    message: 'Penjualan aset berhasil dibatalkan'
                })
                setTimeout(() => this.$router.push('/asset-penjualan'), 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        async approve({id_akun, id_kas}) {
            const params = {
                penjualan_id: this.$route.params.id,
                id_akun,
                id_kas,
                approve: 1
            }

            try {
                
                this.loading = true
                this.$bvModal.hide('kas-modal')
                await this.$store.dispatch('asset/confirmPenjualan', [params])
                this.loading = false
                this.displaySuccess({
                    message: 'Penjualan aset berhasil disetujui'
                })
                setTimeout(() => this.$router.push('/asset-penjualan'), 1000)
            }
            catch(e) {
                this.$bvModal.show('kas-modal')
                this.loading = false
                this.displayError(e)
                return false
            }
        },
        openKasModal() {
            this.$store.commit('asset/SET_CURRENT_TOTAL_PENJUALAN', this.totalRincian)
            this.$bvModal.show('kas-modal')
        },
        setField() {
            if(!this.allowCreate()) {
                this.fields = [
                    {key: 'no', label: 'No'},
                    {key: 'nama', label: 'Nama Aset'},
                    {key: 'harga_jual', label: 'Harga Jual'},
                    {key: 'jumlah', label: 'Qty'},
                    {key: 'satuan', label: 'Satuan'},
                ]
            }
        },
        onChooseAsset(selectedPenyimpanans) {
            selectedPenyimpanans.map(item => this.selectedPenyimpanans.push(item))
            this.$bvModal.hide('modal-penyimpanan')
        },
        async openModalAsset() {
            await this.getPenyimpanan()
            this.$bvModal.show('modal-penyimpanan')
        },
        async remove(item, index) {
            if(item.id) {
                await this.$store.dispatch('asset/savePenjualanRincian', [{id: item.id, fungsi: 2}])
                this.selectedPenyimpanans.splice(index, 1)
            }
            else {
                this.selectedPenyimpanans.splice(index, 1)
            }
        },
        async submit() {
            if(this.selectedPenyimpanans.length < 1) {
                this.displayError({
                    message: 'Harap pilih asset yang hendak dijual!'
                })
                return false
            }

            const payloads = Object.assign([], this.selectedPenyimpanans)
            payloads.map(item => {
                delete item.asset
                delete item.asset_id
                delete item.id_satuan
            })

            try {
                this.loading = true
                await this.$store.dispatch('asset/savePenjualanRincian', payloads)
                this.displaySuccess({
                    message: 'Rincian Penjualan berhasil disimpan!'
                })
                this.getRincian()
                this.loading = false
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }

        },
        async getPenyimpanan() {
            const params = {}
            if(this.myGudang) params.gudang_id = this.myGudang.id
            const stok = await this.$store.dispatch('asset/getStock', params)
        },
        async getRincian() {
            const params = {
                penjualan_id: this.$route.params.id
            }
            const rincians = await this.$store.dispatch('asset/getRincianPenjualan', params)

            this.selectedPenyimpanans = rincians
        },
        async checkCanConfirm() {
            const params = {
                level_id: this.user.level.id
            }
            const menu = await this.currentMenu(this.$route.meta.parent)

            if(menu) params.menu_id = menu.id
            const status = await this.$store.dispatch('statusrole/getData', params)
            this.canConfirm = status.some(item => item.data_status == 1)
            if(this.canConfirm) {
                await this.getAkun()
                await this.getKas()
            }
        },
        async getAkun() {
            const params = {
                jenis: [1]
            }
            const akuns = await this.$store.dispatch('akun/getData', params)
            const akunOptions = akuns.map(akun => {
                const akunName = `${akun.nama} -> Rp ${this.formatRupiah(akun.saldo)}`
                return {text: akunName, value: akun.id, saldo: akun.saldo, kategori: akun.kategori}
            })
            this.$store.commit('asset/SET_DATA_AKUN', akunOptions)
        },
        async getKas() {
            const listKas = await this.$store.dispatch('kas/getData')
            const kasOptions = listKas.map(kas => {
                const kasName = `${kas.nama} -> Rp ${this.formatRupiah(kas.saldo)}`
                return {text: kasName, saldo: kas.saldo, value: kas.id}
            })
            this.$store.commit('asset/SET_DATA_KAS', kasOptions)
        },
    },
    async created() {
        this.loading = true
        await this.checkCanConfirm()
        await this.setField()
        this.getPenyimpanan()
        await this.getRincian()
        this.loading = false
    }
}
</script>